import * as React from 'react';
import SEO from '../meta/SEO';
import CampaignHeader from './CampaignHeader';
import CampaignFooter from './CampaignFooter';

interface CampaignLayoutProps {
  children?: React.ReactNode;
}

export const CampaignLayout: React.FC<CampaignLayoutProps> = ({ children }) => (
  <div className="flex flex-col min-h-screen content">
    <SEO />

    <CampaignHeader />
    <div className="flex flex-col flex-1">{children}</div>
    <CampaignFooter />
  </div>
);
