import { Link } from 'gatsby';
import * as React from 'react';
import { BottomCTA } from './BottomCTA';

const CampaignFooter = () => (
  <footer className="py-4 mt-20 bg-gray-50">
    <div className="container flex flex-col justify-center gap-3 sm:flex-row">
      <p className="text-sm text-gray-500 sm:mr-auto">
        © {new Date().getFullYear()} Nomadago, All Rights Reserved
      </p>

      <Link to="/privacy-policy" className="text-sm text-gray-500">
        Privacy Policy
      </Link>

      <Link to="/terms-and-conditions" className="text-sm text-gray-500">
        Terms and Conditions
      </Link>
    </div>
  </footer>
);

export default CampaignFooter;
