import React from 'react';

interface LogoProps {
  showText?: boolean;
  className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className, showText = true }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 166 40.39"
    className={className}
  >
    {showText && (
      <>
        <path
          d="M61.69 14.84v10.73h-1.76l-5.29-8v8h-1.75V14.84h1.75l5.29 8v-8h1.76Z"
          fill="currentColor"
        />
        <path
          d="M72 25.68a5.37 5.37 0 0 1-5.48-5.26v-.23a5.5 5.5 0 1 1 11 0 5.35 5.35 0 0 1-5.21 5.49H72Zm0-1.53c2 .03 3.66-1.57 3.69-3.57 0-.14 0-.29-.02-.43a3.68 3.68 0 1 0-7.34 0 3.63 3.63 0 0 0 3.67 4Z"
          fill="currentColor"
        />
        <path
          d="M82.26 14.85h1.9l3.76 8.41 3.75-8.41h1.88v10.72h-1.76v-7.34l-3.27 7.34H87.3L84 18.23v7.34h-1.74V14.85Z"
          fill="currentColor"
        />
        <path
          d="M105.41 23.38h-4.49l-.77 2.19h-1.84l3.84-10.73h2L108 25.57h-1.85l-.74-2.19ZM103.17 17l-1.76 5h3.5l-1.74-5Z"
          fill="currentColor"
        />
        <path
          d="M122 20.25c0 3.3-2.25 5.32-5.69 5.32h-3.5V14.85h3.5c3.41 0 5.69 2.09 5.69 5.4Zm-5.69 3.89a3.52 3.52 0 0 0 3.88-3.89c0-2.45-1.35-4-3.88-4h-1.75v7.85l1.75.04Z"
          fill="currentColor"
        />
        <path
          d="M133.25 23.38h-4.48l-.77 2.19h-1.84L130 14.84h2l3.84 10.73H134l-.75-2.19ZM131 17l-1.76 5h3.5L131 17Z"
          fill="currentColor"
        />
        <path
          d="M150.49 17.94h-2.11a3.01 3.01 0 0 0-2.87-1.62 3.6 3.6 0 0 0-3.68 3.5v.37a3.6 3.6 0 0 0 3.31 3.87l.36.01c1.77.1 3.3-1.23 3.43-3h-4V19.7h5.91v1.37a5.29 5.29 0 0 1-5.31 4.59 5.35 5.35 0 0 1-5.54-5.16v-.31a5.35 5.35 0 0 1 5.23-5.47h.24a5.1 5.1 0 0 1 5.02 3.22Z"
          fill="#f8b622"
        />
        <path
          d="M160.52 25.68a5.37 5.37 0 0 1-5.52-5.22v-.27a5.5 5.5 0 1 1 11 0 5.35 5.35 0 0 1-5.21 5.49h-.27Zm0-1.53c2 .03 3.66-1.57 3.69-3.57 0-.14 0-.29-.02-.43a3.68 3.68 0 1 0-7.34 0 3.63 3.63 0 0 0 3.67 4Z"
          fill="#f8b622"
        />
      </>
    )}
    <path
      fill="#1616f4"
      d="M8.08 0h5.39v13.46H0V8.08A8.08 8.08 0 0 1 8.08 0Z"
    />
    <path fill="#174eff" d="M13.46 0v13.46h13.47L13.46 0z" />
    <path
      fill="#2980ff"
      d="M26.93 0h5.39a8.08 8.08 0 0 1 8.08 8.08v5.39H26.93V0Z"
    />
    <path fill="#294ed6" d="M0 13.46h13.46v13.46H0z" />
    <path d="M13.46 13.46h13.46v13.46H13.46z" fill="#3877e5" />
    <path fill="#439dff" d="M26.93 13.46h13.46v13.46H26.93z" />
    <path
      d="M0 26.93h13.46v13.46H8.08A8.08 8.08 0 0 1 0 32.32v-5.39Z"
      fill="#0016b7"
    />
    <path
      d="M32.32 40.39h-5.39V26.93h13.46v5.39a8.09 8.09 0 0 1-8.07 8.07Z"
      fill="#3877e5"
    />
    <path fill="#124ccc" d="m13.46 26.93 13.47 13.46V26.93H13.46z" />
    <path
      d="M34.37 16.77A3.47 3.47 0 0 0 30.92 18a3.3 3.3 0 0 1-2.55 1.17h-2.78A3.37 3.37 0 0 1 22.94 18a3.5 3.5 0 0 0-5.48 0 3.36 3.36 0 0 1-2.65 1.18H12c-.97 0-1.9-.43-2.52-1.18a3.51 3.51 0 1 0 0 4.34 3.37 3.37 0 0 1 2.65-1.17h2.69c1.01-.03 1.99.4 2.65 1.17a3.5 3.5 0 0 0 5.48 0 3.37 3.37 0 0 1 2.65-1.17h2.68c1.01-.03 1.99.4 2.65 1.17a3.5 3.5 0 1 0 3.45-5.61v.03Z"
      fill="#f8b622"
    />
  </svg>
);
