import { Link } from 'gatsby';
import * as React from 'react';
import { IoMailOutline, IoLogoInstagram } from 'react-icons/io5';
import { NewsletterForm } from '../ui/NewsletterForm';
import { Logo } from '../../assets/icons';
import { PathWithPoints } from '../../assets/illustrations/PathWithPoints';

const Footer = () => (
  <footer className="mt-auto overflow-hidden">
    <div className="grid mb-2 mt-20 grid-cols-1 md:grid-cols-[0_1fr_2fr_0] lg:grid-cols-[1fr_minmax(24rem,_1fr)_minmax(48rem,_1fr)_1fr] grid-rows-[auto,auto]">
      <div className="hidden px-4 py-6 md:block md:col-start-2">
        <Logo className="text-blue-500 max-w-48" />
      </div>

      <div className="w-full bg-blue-100 md:col-start-1 md:row-start-2 md:col-span-2 rounded-r-3xl" />

      <div className="relative w-full mx-2 overflow-hidden bg-yellow-400 md:col-start-3 md:col-span-2 md:row-start-1 md:row-span-2 rounded-l-3xl">
        <PathWithPoints className="absolute left-0 right-0 bottom-20" />
      </div>

      <div className="relative flex flex-col items-center w-full px-4 py-8 bg-yellow-400 md:px-8 md:pt-2 md:bg-transparent md:col-start-3 md:row-start-2">
        <NewsletterForm />

        <div className="sticky flex flex-col items-center gap-4 mt-8 sm:flex-row md:self-end right-2 md:mt-20">
          <a
            href="https://www.instagram.com/nomadagotravel"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-1"
          >
            <span className="flex items-center justify-center w-8 h-8 transition-colors bg-blue-600 rounded-full hover:bg-blue-700">
              <IoLogoInstagram className="w-5 h-5 text-blue-50" />
            </span>
            <span className="font-semibold text-blue-500">@nomadagotravel</span>
          </a>

          <a
            href="mailto:help@nomadago.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-1"
          >
            <span className="flex items-center justify-center w-8 h-8 transition-colors bg-blue-600 rounded-full hover:bg-blue-700">
              <IoMailOutline className="w-5 h-5 text-blue-50" />
            </span>
            <span className="font-semibold text-blue-500">
              help@nomadago.com
            </span>
          </a>
        </div>
      </div>

      <div className="relative flex flex-col w-full gap-8 px-4 py-8 bg-blue-100 md:gap-16 md:bg-transparent md:col-start-2 md:row-start-2 md:pt-16">
        <nav>
          <ul className="flex flex-col gap-3 md:gap-5">
            <li>
              <Link to="/faq/" className="text-xl font-semibold">
                FAQs
              </Link>
            </li>

            <li>
              <Link to="/about/" className="text-xl font-semibold">
                About
              </Link>
            </li>

            <li>
              <Link to="/account/login" className="text-xl font-semibold">
                Business Login
              </Link>
            </li>
          </ul>
        </nav>

        <div className="flex flex-col justify-center gap-1 mt-auto">
          <Link to="/privacy-policy" className="text-sm">
            Privacy Policy
          </Link>

          <Link to="/terms-and-conditions" className="text-sm">
            Terms and Conditions
          </Link>

          <p className="text-sm text-blue-700">
            © {new Date().getFullYear()} Nomadago, All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
