import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import cn from 'classnames';
import { TextInput } from './TextInput';

interface LocationInputProps {
  initialValue?: string;
  label?: string;
  onSelect: (location: LocationData) => void;
  required?: boolean;
}

export const LocationInput: React.FC<LocationInputProps> = ({
  initialValue,
  label = 'Full Address',
  onSelect = () => undefined,
  required,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(initialValue);
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);

  const hasChanged = useMemo(
    () => value !== initialValue,
    [value, initialValue],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!value) {
      setSuggestionsOpen(false);
    }
  }, [value]);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesAutocompleteService({
    apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    language: 'en',
    options: {
      input: value || '',
      types: ['address'],
    },
  });

  const handlePredictionClick = useCallback(
    (prediction: any) => {
      const location = {
        fullAddress: prediction.description,
        displayName:
          prediction?.structured_formatting?.secondary_text ??
          prediction?.structured_formatting?.main_text,
        placeId: prediction.place_id,
      } as LocationData;

      placesService?.getDetails(
        {
          placeId: prediction.place_id,
        },
        (placeDetails: any) => {
          const { lat, lng } = placeDetails.geometry.location;
          location.lat = lat();
          location.lng = lng();
          location.city =
            placeDetails.address_components.find((component: any) =>
              component.types.includes('locality'),
            )?.long_name || '';
          location.country =
            placeDetails.address_components.find((component: any) =>
              component.types.includes('country'),
            )?.short_name || '';

          if (location.city && location.country) {
            location.displayName = `${location.city}, ${location.country}`;
          }
        },
      );

      onSelect(location);
      setValue(prediction.description);
      setSuggestionsOpen(false);
    },
    [placesService],
  );

  useEffect(() => {
    if (hasChanged) {
      getPlacePredictions({ input: value || '' });
    }
  }, [value, initialValue]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!wrapperRef.current?.contains(event.target as Node)) {
        setSuggestionsOpen(false);
      }
    },
    [wrapperRef.current],
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('click', handleClickOutside);
      }
    };
  }, [placePredictions, isPlacePredictionsLoading]);

  const handleInput = useCallback(
    (inputValue: string) => {
      setValue(inputValue);
      setSuggestionsOpen(true);
    },
    [setValue],
  );

  return (
    <div className={cn('relative', !!label && 'mt-2')} ref={wrapperRef}>
      <TextInput
        label={label}
        value={value}
        onChange={handleInput}
        loading={isPlacePredictionsLoading}
        required={required}
      />

      {suggestionsOpen && (
        <ul className="absolute z-10 w-full py-2 mt-1 overflow-auto bg-white border border-gray-200 rounded shadow-md max-h-52">
          {isPlacePredictionsLoading &&
            Array.from(Array(5).keys()).map(i => (
              <li className="px-3 py-2 text-sm text-left text-gray-500" key={i}>
                <p className="w-1/2 h-5 bg-gray-100 rounded animate-pulse" />
              </li>
            ))}
          {!isPlacePredictionsLoading &&
            placePredictions.map(prediction => (
              <li key={prediction.place_id}>
                <button
                  className="w-full px-3 py-2 text-sm text-left transition-colors cursor-pointer hover:bg-gray-50"
                  type="button"
                  onClick={() => handlePredictionClick(prediction)}
                >
                  {prediction.description}
                </button>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
