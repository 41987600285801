import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { navigate } from 'gatsby';
import cn from 'classnames';
import {
  IoAddCircleOutline,
  IoCloseOutline,
  IoLogOutOutline,
  IoMenuOutline,
  IoSettingsOutline,
} from 'react-icons/io5';
import { Logo } from '../../assets/icons';
import { useDashboardContext, useWebsiteContext } from '../../context';
import { ConfirmLogoutModal } from '../modals/ConfirmLogoutModal';
import { AddColivingModal } from '../modals/AddColivingModal';
import { AdminButton } from '../ui/AdminButton';
import { Button } from '../ui/Button';
import { CospaceSidebarList } from './CospaceSidebarList';

export const DashboardSidebar: React.FC = () => {
  const contentRef = useRef<any>(null);
  const { isMobile } = useWebsiteContext();
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const {
    colivings,
    coworkings,
    activeLocation,
    setActiveLocation = () => undefined,
  } = useDashboardContext();
  const { setModalContent } = useWebsiteContext();

  const handleLocationChange = useCallback((id: string) => {
    setNavOpen(false);
    navigate(`/dashboard/${id}/`);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setNavOpen(false);
      contentRef.current?.scrollTo(0, 0);
    }
  }, [isMobile]);

  useEffect(() => {
    if (navOpen) {
      disablePageScroll(contentRef.current);

      return () => {
        enablePageScroll(contentRef.current);
      };
    }

    return () => undefined;
  }, [navOpen]);

  const maxNavHeight = useMemo(() => {
    if (!isMobile) {
      return 'none';
    }

    if (navOpen) {
      return '100vh';
    }

    return '4rem';
  }, [navOpen, isMobile]);

  return (
    <nav
      className="fixed top-0 bottom-0 left-0 z-50 flex flex-col w-full overflow-auto transition-all shadow md:w-72"
      style={{
        maxHeight: maxNavHeight,
      }}
    >
      <button
        type="button"
        className={cn(
          'absolute inset-0 bg-white bg-opacity-50 md:hidden transition-opacity',
          {
            'opacity-0 pointer-events-none': !navOpen,
          },
        )}
        onClick={() => setNavOpen(false)}
        aria-label="Close Nav"
      />

      <div className="relative flex justify-between p-4 mb-2 bg-white shadow md:shadow-none">
        <Logo className="h-8 text-blue-500" showText={!isMobile} />

        <button
          type="button"
          className={cn(
            'relative flex items-center justify-center md:hidden w-8 h-8 transition-colors rounded-full focus:outline-none',
            navOpen ? 'text-blue-500' : 'text-gray-600',
          )}
          onClick={() => setNavOpen(!navOpen)}
        >
          <IoMenuOutline
            className={cn(
              'absolute inset-0 w-8 h-8 transition transform',
              navOpen ? 'opacity-0 scale-50' : 'opacity-100',
            )}
          />
          <IoCloseOutline
            className={cn(
              'absolute inset-0 w-8 h-8 transition-transform',
              navOpen ? 'opacity-100' : 'opacity-0 scale-50',
            )}
          />
        </button>
      </div>

      <div
        ref={contentRef}
        className="relative flex flex-col h-full gap-5 overflow-auto bg-white shadow"
      >
        <CospaceSidebarList
          type="Coliving"
          cospaces={colivings}
          handleLocationChange={handleLocationChange}
        />

        <CospaceSidebarList
          type="Coworking"
          cospaces={coworkings}
          handleLocationChange={handleLocationChange}
        />

        <div className="sticky bottom-0 flex gap-1 px-2 py-4 mt-auto bg-white border-gray-100 md:border-t md:gap-0 md:flex-col">
          <AdminButton
            active={activeLocation === 'settings'}
            onClick={() => {
              setNavOpen(false);
              setActiveLocation('settings');
              navigate('/dashboard/settings/');
            }}
          >
            <IoSettingsOutline className="w-5 h-5" />
            <span>Settings</span>
          </AdminButton>

          <AdminButton
            onClick={() =>
              setModalContent({
                title: 'Log Out',
                content: <ConfirmLogoutModal />,
              })
            }
          >
            <IoLogOutOutline className="w-5 h-5" />
            <span>Log Out</span>
          </AdminButton>
        </div>
      </div>
    </nav>
  );
};
