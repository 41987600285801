import React from 'react';
import cn from 'classnames';

interface HamburgerButtonProps {
  className?: string;
  onClick: () => void;
  isOpen: boolean;
}

const commonClasses =
  'w-full h-0.5 bg-blue-500 rounded transform transition-transform duration-300';

export const HamburgerButton: React.FC<HamburgerButtonProps> = ({
  className,
  onClick,
  isOpen,
}) => (
  <button
    className={cn(
      'flex flex-col justify-between w-7 h-5 border-none outline-none cursor-pointer focus:outline-none',
      className,
    )}
    onClick={() => onClick()}
    type="button"
    aria-label="Toggle Navigation Menu"
    data-testid="hamburger-button"
  >
    <span
      className={cn(commonClasses, 'origin-right', {
        '-rotate-45 -translate-x-0.5 -translate-y-px': isOpen,
      })}
    />

    <span
      className={cn(commonClasses, 'duration-150 origin-center', {
        'scale-x-0': isOpen,
      })}
    />

    <span
      className={cn(commonClasses, 'origin-right', {
        'rotate-45 -translate-x-0.5 translate-y-px': isOpen,
      })}
    />
  </button>
);
