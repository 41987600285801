import React from 'react';
import { navigate } from 'gatsby';
import * as Sentry from '@sentry/gatsby';
import { useAuth } from '../../hooks/useAuth';
import { Button } from '../ui/Button';
import { useWebsiteContext } from '../../context';

export const ConfirmLogoutModal = () => {
  const { logout } = useAuth();
  const { setModalContent } = useWebsiteContext();

  const handleLogout = async () => {
    try {
      await logout();
      setModalContent(null);
      navigate('/account/login/');
    } catch (error) {
      console.error('Error logging out: ', error);
      Sentry.captureException(error, {
        tags: {
          page: 'dashboard',
          query: 'handleSignOut',
        },
      });
    }
  };

  return (
    <>
      <p className="mb-5">Are you sure you want to log out?</p>

      <div className="flex items-center justify-end gap-2">
        <Button type="secondary" onClick={() => setModalContent(null)}>
          Cancel
        </Button>
        <Button onClick={handleLogout}>Log Out</Button>
      </div>
    </>
  );
};
