import { Auth } from '@aws-amplify/auth';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client';
import awsConfig from './aws-config';

const url = awsConfig.aws_appsync_graphqlEndpoint as string;
const region = awsConfig.aws_appsync_region as string;

const auth = {
  type: awsConfig.aws_appsync_authenticationType,
  jwtToken: async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken(),
} as AuthOptions;

const httpLink = new HttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const instance = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default instance;
