import React, { useMemo } from 'react';
import cn from 'classnames';
import { LoadingSpinner } from './LoadingSpinner';

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  href?: string;
  loading?: boolean;
  onClick?: () => void;
  type?: 'primary' | 'secondary' | 'outline' | 'error' | 'error-outline';
}

export const Button: React.FC<
  ButtonProps &
    React.HTMLProps<HTMLAnchorElement> &
    React.HTMLProps<HTMLButtonElement>
> = ({
  type = 'primary',
  children,
  className = '',
  disabled = false,
  htmlType = 'button',
  href,
  loading = false,
  onClick = () => undefined,
  ...props
}) => {
  const Element = useMemo(() => (href ? 'a' : 'button'), [href]);

  const buttonModifierClass = useMemo(() => {
    switch (type) {
      case 'outline':
        return 'button--outline';
      case 'secondary':
        return 'button--secondary';
      case 'error':
        return 'button--error';
      case 'error-outline':
        return 'button--error-outline';
      case 'primary':
      default:
        return 'button--primary';
    }
  }, [type]);

  const loaderClass = useMemo(() => {
    switch (type) {
      case 'outline':
        return 'button__loader--outline';
      case 'secondary':
        return 'button__loader--secondary';
      case 'error':
        return 'button__loader--error';
      case 'error-outline':
        return 'button__loader--error-outline';
      case 'primary':
      default:
        return 'button__loader--primary';
    }
  }, [type]);

  return (
    <Element
      type={htmlType}
      className={cn('relative button', buttonModifierClass, className, {
        'button--disabled': disabled,
        'button--loading': loading,
      })}
      disabled={disabled || loading}
      onClick={onClick}
      href={href}
      {...props}
    >
      {children}

      {loading && <LoadingSpinner className={loaderClass} />}
    </Element>
  );
};
