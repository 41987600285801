import { createContext, useContext } from 'react';

interface DashboardState {
  colivings?: Coliving[];
  coworkings?: Coworking[];
  businessDataLoading?: boolean;
  activeLocation?: string;
  setActiveLocation?: (id: string) => void;
  user?: UserData;
}

export const DashboardContext = createContext({
  colivings: [],
  coworkings: [],
  businessDataLoading: true,
  activeLocation: '',
  setActiveLocation: () => undefined,
  user: undefined,
} as DashboardState);

export const useDashboardContext = () => useContext(DashboardContext);
