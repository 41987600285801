import React from 'react';
import cn from 'classnames';

interface SectionHeaderProps {
  heading?: string;
  subheading?: string;
  className?: string;
  headingClassName?: string;
  subheadingClassName?: string;
  alignment?: 'left' | 'center' | 'right';
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  heading,
  subheading,
  className,
  headingClassName,
  subheadingClassName,
  alignment = 'center',
}) => (
  <header
    className={cn(className, {
      'text-left': alignment === 'left',
      'text-center': alignment === 'center',
      'text-right': alignment === 'right',
    })}
  >
    <h2
      className={cn(
        'max-w-3xl mx-auto text-2xl font-bold text-blue-500 sm:text-4xl',
        headingClassName,
      )}
    >
      {heading}
    </h2>

    {subheading && (
      <p
        className={cn('max-w-xl mx-auto mt-2 sm:text-lg', subheadingClassName)}
      >
        {subheading}
      </p>
    )}
  </header>
);
