import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { Button } from './Button';
import { SectionHeader } from './SectionHeader';
import { checkEmailValid } from '../../utils/validators';
import { TextInput } from './TextInput';

export const NewsletterForm: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);

  const emailValid = useMemo(() => checkEmailValid(email), [email]);

  useEffect(() => {
    if (emailValid) {
      setError('');
    }
  }, [email]);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!emailValid) {
        setError('Please enter a valid email address');
        return;
      }

      const data = new FormData();
      data.append('EMAIL', email);
      data.append('email_address_check', '');
      data.append('locale', 'en');

      try {
        setSending(true);

        await fetch(
          'https://7b1fd501.sibforms.com/serve/MUIFAKV_V6l4wU_OfmUvnpSgPHijnt_0tPNeHi1jfi0PG3Lbgcq4fAJHfjCU-e5v1o6f8kQDqfXJZjs24pUjMpTIn2J-UFEA6dSDD3HuTmUzcj_3wpiWFBzAIioFgJrNhrNqbyFTrDia0BVtwzwDQLN2r_dJBHWONSlkyotOPUZh6ixfnvnNffOihwwpMkdT1UfsWveux8N_X_h5',
          {
            method: 'POST',
            body: data,
            mode: 'no-cors',
            headers: {
              Accept: 'application/json',
            },
          },
        );
      } catch (e) {
        console.error(e);
      } finally {
        setSending(false);
      }
    },
    [email, emailValid],
  );

  return (
    <form
      onSubmit={e => handleSubmit(e)}
      className="sticky flex flex-col w-full gap-2 md:max-w-lg right-2"
    >
      <SectionHeader
        heading="Subscribe To Our Newsletter"
        subheading="Get the latest news, tips and special offers from Nomadago"
        className="mb-2"
        alignment="left"
      />

      <div className="flex flex-col items-stretch gap-3 md:items-start md:flex-row">
        <TextInput
          placeholder="Enter your email address"
          value={email}
          name="email"
          onChange={value => setEmail(value)}
          required
          error={error}
          className="flex-1"
          wrapperClassName={cn('bg-white overflow-hidden')}
        />

        <Button htmlType="submit" loading={sending}>
          Subscribe
        </Button>
      </div>
    </form>
  );
};
