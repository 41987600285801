import React from 'react';
import cn from 'classnames';
import { IoAddCircleOutline, IoChevronForward } from 'react-icons/io5';
import { useDashboardContext, useWebsiteContext } from '../../context';
import { UserImage } from '../ui/UserImage';
import { AddColivingModal } from '../modals/AddColivingModal';
import { AddCoworkingModal } from '../modals/AddCoworkingModal';
import { ColivingIcon, CoworkingIcon } from '../../assets/icons';

interface CospaceSidebarListProps {
  type: 'Coliving' | 'Coworking';
  cospaces?: Coliving[] | Coworking[];
  handleLocationChange?: (id: string) => void;
}

export const CospaceSidebarList: React.FC<CospaceSidebarListProps> = ({
  type = 'Coliving',
  cospaces = [],
  handleLocationChange = () => undefined,
}) => {
  const { setModalContent } = useWebsiteContext();
  const { businessDataLoading, activeLocation } = useDashboardContext();

  return (
    <div className="px-3">
      <div className="flex items-center justify-between gap-2 px-1 mb-3">
        <div className="flex items-center gap-1.5">
          {type === 'Coliving' && (
            <ColivingIcon className="w-5 h-5 text-blue-500" />
          )}

          {type === 'Coworking' && (
            <CoworkingIcon className="w-5 h-5 text-blue-500" />
          )}

          <h5 className="text-sm font-medium text-gray-500">{type}</h5>
        </div>

        <button
          type="button"
          className="flex items-center gap-0.5 text-gray-500 hover:text-blue-500"
          onClick={() =>
            setModalContent({
              title: `Add New ${type}`,
              content:
                type === 'Coliving' ? (
                  <AddColivingModal />
                ) : (
                  <AddCoworkingModal />
                ),
            })
          }
        >
          <IoAddCircleOutline className="w-5 h-5" />

          <span className="text-xs font-medium">Add Location</span>
        </button>
      </div>

      <ul className="flex flex-col w-full">
        {businessDataLoading && (
          <>
            {[...Array(3)].map((_, index) => (
              <li
                className="flex items-center w-full gap-2 p-2 mb-1 rounded-md bg-gray-50 last:mb-0 animate-pulse"
                key={index} // eslint-disable-line react/no-array-index-key
              >
                <figure className="w-8 h-8 overflow-hidden bg-gray-200 border-2 border-white rounded-full" />
                <div className="w-40 h-4 bg-gray-200 rounded" />
              </li>
            ))}
          </>
        )}

        {!businessDataLoading && (
          <>
            {cospaces.length === 0 && (
              <li className="w-full px-2 py-4 text-center border border-gray-100 border-dashed rounded-md">
                <h6 className="text-xs font-medium text-gray-300">
                  No {type} Locations
                </h6>
              </li>
            )}

            {cospaces.length > 0 &&
              cospaces.map(cospace => (
                <li className="mb-1 last:mb-0" key={cospace.id}>
                  <button
                    type="button"
                    onClick={() => handleLocationChange(cospace.id)}
                    className={cn(
                      'flex gap-2 items-center w-full rounded-md p-2 font-medium text-sm transition-colors',
                      activeLocation === cospace.id
                        ? 'bg-blue-100'
                        : 'hover:bg-blue-50',
                    )}
                  >
                    <UserImage
                      imgKey={cospace?.logo}
                      className="w-8 h-8 shadow"
                      label={cospace?.name}
                    />

                    <span className="flex-1 text-sm font-medium text-left truncate">
                      {cospace.name}
                    </span>

                    <IoChevronForward
                      size={12}
                      className={
                        activeLocation === cospace.id
                          ? 'text-blue-500'
                          : 'text-blue-200'
                      }
                    />
                  </button>
                </li>
              ))}
          </>
        )}
      </ul>
    </div>
  );
};
