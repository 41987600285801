import * as React from 'react';
import Footer from './Footer';
import Header from './Header';
import SEO from '../meta/SEO';

interface PageLayoutProps {
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => (
  <div className="flex flex-col min-h-screen content">
    <SEO />

    <Header />
    <div className="flex flex-col flex-1">{children}</div>
    <Footer />
  </div>
);
