import React, { useCallback } from 'react';
import cn from 'classnames';
import { AppStore, GooglePlay } from '../../assets/icons';
import { useWebsiteContext } from '../../context';

interface DownloadButtonsProps {
  className?: string;
  buttonClassName?: string;
}

const DownloadButtons: React.FC<DownloadButtonsProps> = ({
  className = '',
  buttonClassName = '',
}) => {
  const { appStoreLink, googlePlayLink } = useWebsiteContext();

  const handleDownloadClick = useCallback(
    (url: string) => {
      if (typeof window !== 'undefined') {
        // @ts-expect-error gtag is not defined
        if (window.gtag) {
          console.log(
            `firing event - 
          ${
            url === googlePlayLink
              ? 'android_download_click'
              : 'ios_download_click'
          }`,
          );

          // @ts-expect-error gtag is not defined
          window.gtag(
            'event',
            url === googlePlayLink
              ? 'android_download_click'
              : 'ios_download_click',
          );
        }

        window.location.href = url;
      }
    },
    [appStoreLink, googlePlayLink],
  );

  return (
    <div className={cn('flex gap-2', className)}>
      <button
        type="button"
        onClick={() => handleDownloadClick(googlePlayLink)}
        aria-label="Download on Google Play"
        className={cn(
          'flex-1 sm:flex-initial transition-all hover:shadow-md hover:opacity-80',
          buttonClassName,
        )}
      >
        <GooglePlay className="w-full" />
      </button>

      <button
        type="button"
        onClick={() => handleDownloadClick(appStoreLink)}
        aria-label="Download on App Store"
        className={cn(
          'flex-1 sm:flex-initial transition-all hover:shadow-md hover:opacity-80',
          buttonClassName,
        )}
      >
        <AppStore className="w-full" />
      </button>
    </div>
  );
};

export default DownloadButtons;
