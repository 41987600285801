import React from 'react';

export const CoworkingIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg className={className} viewBox="0 0 483.93 449">
    <path
      d="M231.03 224c52.79 0 99.43-49.71 103.96-110.82 2.27-30.7-7.36-59.32-27.11-80.6C288.36 11.57 261.07 0 231.03 0s-57.58 11.5-77 32.38c-19.63 21.11-29.21 49.8-26.95 80.78C131.53 174.28 178.16 224 231.04 224ZM177.46 54.18C190.76 39.88 209.78 32 231.03 32s40 7.94 53.39 22.36c13.64 14.68 20.26 34.73 18.65 56.46-3.27 44-36.26 81.18-72.04 81.18s-68.84-37.17-72.04-81.16c-1.6-22.01 4.96-42.13 18.47-56.66ZM285.79 264.98a34.8 34.8 0 0 0-34.75-33.91H34.76A34.8 34.8 0 0 0 0 265.83c0 .5.02 1 .07 1.49l14.67 146.77A34.8 34.8 0 0 0 49.49 448h216.28a34.8 34.8 0 0 0 34.76-34.76c0-.5-.02-1-.07-1.49l-14.67-146.77ZM265.77 418H49.5a4.76 4.76 0 0 1-4.76-4.76c0-.5-.02-1-.07-1.49L30.03 265.34a4.77 4.77 0 0 1 4.74-4.27h216.28a4.76 4.76 0 0 1 4.76 4.76c0 .5.02 1 .07 1.49l14.64 146.41a4.77 4.77 0 0 1-4.74 4.27ZM466.11 311.09H322.93a11 11 0 0 0-11 11v81.82A45.14 45.14 0 0 0 357.02 449h47.73a45.14 45.14 0 0 0 45.09-45.09V374h3.49a30.64 30.64 0 0 0 30.6-30.6v-14.49c0-9.83-7.99-17.82-17.82-17.82Zm-38.27 92.82A23.12 23.12 0 0 1 404.75 427h-47.73a23.12 23.12 0 0 1-23.09-23.09v-70.82h93.91v70.82Zm34.09-60.51c0 4.74-3.86 8.6-8.6 8.6h-3.49v-18.91h12.09v10.31ZM364.59 297.01a168.4 168.4 0 0 0-4.37-3c-19.19-12.79-41.06-22.58-64.37-29.02l3.46 34.66c3.35.81 5.38 1.32 5.55 1.35.36.06.71.09 1.07.09h57.36a2.24 2.24 0 0 0 1.29-4.08Z"
      fill="currentColor"
    />
    <path
      d="M150.27 320.65c-10.41 0-18.88 8.47-18.88 18.88s8.47 18.88 18.88 18.88 18.88-8.47 18.88-18.88-8.47-18.88-18.88-18.88Z"
      fill="currentColor"
    />
  </svg>
);
