import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Modal from '../components/modals/Modal';

interface WebsiteState {
  modalContent?: ModalContent | null;
  setModalContent: (content: ModalContent | null) => void;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  appStoreLink: string;
  setAppStoreLink: (link: string) => void;
  googlePlayLink: string;
  setGooglePlayLink: (link: string) => void;
}

export const WebsiteContext = createContext({
  modalContent: null,
  setModalContent: () => undefined,
  isMobile: false,
  setIsMobile: () => undefined,
  appStoreLink:
    'https://apps.apple.com/app/apple-store/id1587419846?pt=123485337&ct=Nomadago%20Website&mt=8',
  setAppStoreLink: () => undefined,
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=com.nomadago.nomadago&referrer=utm_source%3Dnomadago.com%26utm_campaign%3Dwebsite-traffic',
  setGooglePlayLink: () => undefined,
} as WebsiteState);

export const useWebsiteContext = () => useContext(WebsiteContext);

export const WebsiteProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [modalContent, setModalContent] = useState<ModalContent | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [appStoreLink, setAppStoreLink] = useState<string>(
    'https://apps.apple.com/app/apple-store/id1587419846?pt=123485337&ct=Nomadago%20Website&mt=8',
  );
  const [googlePlayLink, setGooglePlayLink] = useState<string>(
    'https://play.google.com/store/apps/details?id=com.nomadago.nomadago&referrer=utm_source%3Dnomadago.com%26utm_campaign%3Dwebsite-traffic',
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const utmCampaign = urlParams.get('utm_campaign');

      if (utmCampaign === 'verified_partnership') {
        const utmSource = urlParams.get('utm_source');
        setAppStoreLink(
          `https://apps.apple.com/app/apple-store/id1587419846?pt=123485337&ct=${utmSource}&mt=8`,
        );

        setGooglePlayLink(
          `https://play.google.com/store/apps/details?id=com.nomadago.nomadago&referrer=utm_source%3D${utmSource}%26utm_campaign%3Dverified_partnership`,
        );
      }
    }
  }, []);

  const checkMobile = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 768) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      checkMobile();
      window.addEventListener('resize', checkMobile);

      return () => {
        window.removeEventListener('resize', checkMobile);
      };
    }

    return () => undefined;
  }, []);

  const websiteState = useMemo(
    () => ({
      modalContent,
      setModalContent,
      isMobile,
      setIsMobile,
      appStoreLink,
      setAppStoreLink,
      googlePlayLink,
      setGooglePlayLink,
    }),
    [modalContent, isMobile, appStoreLink, googlePlayLink],
  );

  return (
    <WebsiteContext.Provider value={websiteState}>
      {children}

      <main>{modalContent && <Modal />}</main>
    </WebsiteContext.Provider>
  );
};
