import { gql } from '@apollo/client';

export const createCoworking = gql`
  mutation createCoworking(
    $name: String
    $description: String
    $location: CoworkingLocationInput
    $website: String
  ) {
    createCoworking(
      coworkingInput: {
        name: $name
        website: $website
        location: $location
        description: $description
      }
    ) {
      id
    }
  }
`;
