import { gql } from '@apollo/client';

export const getBusinessData = gql`
  query getBusinessData($userId: String!) {
    business(userId: $userId) {
      colivings {
        id
        name
        logo
      }
      coworkings {
        id
        name
        logo
      }
    }
    user(userId: $userId, requesterId: $userId) {
      id
      name
      username
      bio
      image
      email
      phoneNumber {
        countryCode
        digits
      }
    }
  }
`;
