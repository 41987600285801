import React from 'react';
import cn from 'classnames';

interface AdminButtonProps {
  active?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const AdminButton: React.FC<AdminButtonProps> = ({
  active = false,
  onClick = () => undefined,
  children,
}) => (
  <button
    type="button"
    className={cn(
      'flex items-center text-blue-500 justify-center flex-1 gap-1 px-2 py-3 md:py-2 text-xs font-medium text-left truncate rounded md:bg-transparent  bg-blue-50 md:justify-start w-max hover:text-blue-500',
      {
        'md:text-gray-600': !active,
      },
    )}
    onClick={onClick}
  >
    {children}
  </button>
);
