import { gql } from '@apollo/client';

export const createColiving = gql`
  mutation createColiving(
    $name: String
    $description: String
    $location: ColivingLocationInput
    $website: String
  ) {
    createColiving(
      colivingInput: {
        name: $name
        website: $website
        location: $location
        description: $description
      }
    ) {
      id
    }
  }
`;
