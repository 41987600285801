import React, { useCallback, useMemo, useState } from 'react';
import * as Sentry from '@sentry/gatsby';
import { API } from '@aws-amplify/api';
import { Button } from '../ui/Button';
import { useWebsiteContext } from '../../context';
import { TextArea } from '../ui/TextArea';

interface FeedbackModalProps {
  user: UserData | null;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({ user }) => {
  const { setModalContent } = useWebsiteContext();
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const formattedMessage = useMemo(
    () => `
    <p><strong>Submitter ID:</strong> ${user?.id}</p>
    <p><strong>Submitter Name:</strong> ${user?.name}</p>
    <p><strong>Submitter Email:</strong> ${user?.email}</p>
    <br /><br /><br /><br />
    <p><strong>Message:</strong> ${message}</p>
  `,
    [user, message],
  );

  const handleSend = useCallback(async () => {
    const formData = {
      submitterId: user?.id,
      formattedSubject: 'Feedback from Dashboard',
      formattedMessage,
    };

    try {
      setLoading(true);
      const apiName = 'SendReportEmail';
      const path = '/send-email';
      const myInit = { body: formData };

      const res = await API.post(apiName, path, myInit);

      return res;
    } catch (error) {
      setLoading(false);
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          component: 'FeedbackModal',
          query: 'handleSend',
        },
      });
    } finally {
      setModalContent(null);
    }

    return null;
  }, [user, formattedMessage]);

  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={e => {
        e.preventDefault();
        handleSend();
      }}
    >
      <TextArea
        label="Description"
        value={message}
        onChange={setMessage}
        placeholder="We are hard at work building this tool for our partners! Any feedback is greatly appreciated."
      />

      <div className="flex items-center justify-end">
        <Button
          htmlType="submit"
          className="min-w-32"
          disabled={!message}
          loading={loading}
        >
          Send
        </Button>
      </div>
    </form>
  );
};
