import React, { useMemo } from 'react';
import cuid from 'cuid';
import cn from 'classnames';
import { LoadingSpinner } from './LoadingSpinner';

export interface TextInputProps {
  className?: string;
  wrapperClassName?: string;
  disabled?: boolean;
  error?: string;
  icon?: React.ReactNode;
  id?: string;
  inputClassName?: string;
  label?: string;
  loading?: boolean;
  maxLength?: number;
  name?: string;
  onChange: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  required?: boolean;
  value?: string;
  type?: string;
  [key: string]: any;
}

export const TextInput: React.FC<TextInputProps> = ({
  className,
  wrapperClassName,
  error,
  icon,
  id,
  inputClassName,
  label,
  loading,
  maxLength,
  name,
  onChange,
  placeholder,
  required,
  value,
  type = 'text',
  ...props
}: TextInputProps) => {
  const inputId = useMemo<string>(() => id ?? `TextInput-${cuid()}`, []);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      onChange(event.target.value.replace(/[^0-9]/g, ''));
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <label htmlFor={inputId} className={cn({ 'mt-2': !!label }, className)}>
      <div
        className={cn(
          'relative flex items-center gap-1 pb-1 px-2 transition-all border rounded shadow-blue-500 focus-within:shadow',
          {
            ' focus-within:text-blue-500 focus-within:border-blue-500': !error,
            'border-red-500 text-red-500': error,
            'pt-2': label,
            'pt-1': !label,
          },
          wrapperClassName,
        )}
      >
        {label && (
          <span
            className={cn(
              'absolute px-1 text-xs font-normal bg-white left-2 -top-2',
            )}
          >
            {label}
            {required && ' *'}
          </span>
        )}

        {icon}

        <input
          id={inputId}
          name={name ?? inputId}
          type={type === 'number' ? 'text' : type}
          placeholder={placeholder}
          className={cn(
            'flex-1 p-1 text-black outline-none',
            inputClassName,
            loading && 'pr-6',
          )}
          onChange={handleInput}
          value={value}
          maxLength={maxLength}
          required={required}
          {...props}
        />

        {loading && (
          <div className="absolute top-0 bottom-0 w-4 right-2">
            <LoadingSpinner size="sm" color="blue" />
          </div>
        )}
      </div>

      {error && <p className="mt-1.5 text-xs text-red-500">{error}</p>}
    </label>
  );
};
