import React, { useMemo } from 'react';
import cuid from 'cuid';
import cn from 'classnames';

export interface TextAreaProps {
  className?: string;
  error?: string;
  inputClassName?: string;
  label?: string;
  maxLength?: number;
  onChange: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  value: string;
  type?: string;
}

export const TextArea: React.FC<TextAreaProps> = ({
  className,
  error,
  inputClassName,
  label,
  maxLength,
  onChange,
  placeholder,
  required,
  value,
  type = 'text',
  ...props
}: TextAreaProps) => {
  const inputId = useMemo<string>(() => `TextArea-${cuid()}`, []);

  const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (type === 'number') {
      onChange(event.target.value.replace(/[^0-9]/g, ''));
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <label htmlFor={inputId} className={cn({ 'mt-2': !!label }, className)}>
      <div
        className={cn(
          'relative flex items-center gap-1 pb-1 px-2 transition-all border rounded shadow-blue-500 focus-within:shadow',
          {
            ' focus-within:text-blue-500 focus-within:border-blue-500': !error,
            'border-red-500 text-red-500': error,
            'pt-2': label,
            'pt-1': !label,
          },
        )}
      >
        {label && (
          <span
            className={cn(
              'absolute px-1 text-xs font-normal bg-white left-2 -top-2',
            )}
          >
            {label}
            {required && ' *'}
          </span>
        )}

        <textarea
          id={inputId}
          placeholder={placeholder}
          className={cn(
            'flex-1 p-1 text-black resize-none min-h-32 outline-none',
            inputClassName,
          )}
          onChange={handleInput}
          value={value}
          maxLength={maxLength}
          required={required}
          {...props}
        />
      </div>

      {error && <p className="mt-1.5 text-xs text-red-500">{error}</p>}
    </label>
  );
};
