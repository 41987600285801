import React from 'react';
import { Amplify } from '@aws-amplify/core';
import { ApolloProvider } from '@apollo/client';
import '@fontsource/inter/latin.css';
import '@fontsource/poppins/latin.css';
import './src/styles/global.scss';
import awsConfig from './src/lib/aws-config';
import instance from './src/lib/apollo-config';
import { DashboardLayout } from './src/components/layout/DashboardLayout';
import { PageLayout } from './src/components/layout/PageLayout';
import { CampaignLayout } from './src/components/layout/CampaignLayout';
import { WebsiteProvider } from './src/context/WebsiteContext';

Amplify.configure(awsConfig);

export const wrapRootElement: React.FC<{ element: React.ReactNode }> = ({
  element,
}) => (
  <ApolloProvider client={instance}>
    <WebsiteProvider>{element}</WebsiteProvider>
  </ApolloProvider>
);

export const wrapPageElement: React.FC<{
  element: React.ReactNode;
  props: {
    uri: string;
    location: {
      pathname: string;
    };
  };
}> = ({ element, props }) => {
  const { uri, location } = props;

  if (uri.includes('dashboard') || location.pathname.includes('dashboard')) {
    return (
      <DashboardLayout entryPath={uri} {...props}>
        {element}
      </DashboardLayout>
    );
  }

  if (uri.includes('campaign') || location.pathname.includes('campaign')) {
    return <CampaignLayout {...props}>{element}</CampaignLayout>;
  }

  return <PageLayout {...props}>{element}</PageLayout>;
};
