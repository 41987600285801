import { Auth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/gatsby';

interface ChangeAttributesParams {
  name?: string;
  username?: string;
  email?: string;
  phoneNumber?: string;
}

export const useAuth = () => {
  const handleSuccessfulLogin = (user: any) => {
    const authData = {
      ...user,
      userId: user.attributes['custom:userId'],
      token: user.signInUserSession.accessToken.jwtToken,
    } as any;

    Sentry.setUser({ id: user.attributes['custom:userId'] });

    return { authData };
  };

  const checkAuthStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const { authData } = handleSuccessfulLogin(user);

      return { user: authData };
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          function: 'checkAuthStatus',
        },
      });
      return { error };
    }
  };

  const loginWithEmail = async (email: string, password: string) => {
    const formattedEmail = email.toLowerCase();

    try {
      const user = await Auth.signIn(formattedEmail, password, {
        origin: 'business',
      });

      const { authData } = handleSuccessfulLogin(user);

      return { user: authData };
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          function: 'loginWithEmail',
          email: formattedEmail,
        },
      });

      throw error;
    }
  };

  const resendVerification = async (email: string) => {
    const formattedEmail = email.toLowerCase();

    try {
      const result = await Auth.resendSignUp(formattedEmail);
      return { result };
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          function: 'resendVerification',
          email: formattedEmail,
        },
      });
      return { error };
    }
  };

  const forgotPassword = async (email: string) => {
    const formattedEmail = email.toLowerCase();

    try {
      const result = await Auth.forgotPassword(formattedEmail);
      return { result };
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          function: 'forgotPassword',
          email: formattedEmail,
        },
      });
      return { error } as any;
    }
  };

  const forgotPasswordSubmit = async (
    email: string,
    code: string,
    password: string,
  ) => {
    const formattedEmail = email.toLowerCase();

    try {
      const result = await Auth.forgotPasswordSubmit(
        formattedEmail,
        code,
        password,
      );
      return { result };
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          function: 'forgotPasswordSubmit',
          email: formattedEmail,
        },
      });
      return { error };
    }
  };

  const changePassword = async (
    oldPassword: string,
    newPassword: string,
  ): Promise<any> => {
    try {
      const result = await Auth.currentAuthenticatedUser().then(user =>
        Auth.changePassword(user, oldPassword, newPassword),
      );
      return { result };
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          function: 'changePassword',
        },
      });
      return { error };
    }
  };

  const changeAttributes = async ({
    name,
    username,
    email,
    phoneNumber,
  }: ChangeAttributesParams): Promise<any> => {
    const attributes = {} as any;
    const formattedEmail = email?.toLowerCase();

    if (name) {
      attributes.name = name;
    }

    if (email) {
      attributes.email = formattedEmail;
    }

    if (username) {
      attributes.preferred_username = username;
    }

    if (Object.keys(attributes).length > 0) {
      try {
        const result = await Auth.currentAuthenticatedUser().then(user =>
          Auth.updateUserAttributes(user, attributes),
        );
        return { result };
      } catch (error) {
        console.error(error);
        Sentry.captureException(error, {
          tags: {
            function: 'changeAttributes',
            email: formattedEmail,
            name,
            username,
            phoneNumber,
          },
        });
        return { error };
      }
    }

    return { result: null };
  };

  const verifyNewEmail = async (code: string): Promise<any> => {
    try {
      const result = await Auth.currentAuthenticatedUser().then(user =>
        Auth.verifyUserAttributeSubmit(user, 'email', code),
      );
      return { result };
    } catch (error) {
      return { error };
    }
  };

  const verifyPhoneNumber = async (code: string): Promise<any> => {
    try {
      const result = await Auth.currentAuthenticatedUser().then(user =>
        Auth.verifyUserAttributeSubmit(user, 'phone', code),
      );
      return { result };
    } catch (error) {
      return { error };
    }
  };

  const logout = async (): Promise<any> => {
    try {
      const result = await Auth.signOut();
      return { result };
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          function: 'logout',
        },
      });
      return { error };
    }
  };

  return {
    checkAuthStatus,
    loginWithEmail,
    resendVerification,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    changeAttributes,
    verifyNewEmail,
    verifyPhoneNumber,
    logout,
  };
};
