import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://9da6f3de5aaf44d1a7063fee97d7b1d7@o1337733.ingest.sentry.io/4505437800955904',
  sampleRate: 0.7,
  replaysSessionSampleRate: 0.7,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production',
});
