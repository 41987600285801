import React from 'react';

export const ColivingIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg className={className} viewBox="0 0 472 439.98">
    <path
      d="M396 439.98H76c-15.44 0-28-12.56-28-28v-236a12 12 0 1 1 24 0v236a4 4 0 0 0 4 4h320a4 4 0 0 0 4-4v-236a12 12 0 1 1 24 0v236c0 15.44-12.56 28-28 28Z"
      fill="currentColor"
    />
    <path
      d="M12 231.98a12 12 0 0 1-8.3-20.67L216.65 7.46C221.32 2.71 228.35 0 235.99 0h.05c7.65.01 14.66 2.73 19.31 7.48l64.64 61.88V27.98a12 12 0 0 1 12-12h48a12 12 0 0 1 12 12v110.3l76.3 73.04a12 12 0 0 1-16.6 17.34L238.59 24.65l-.23-.23c-1.04-.56-3.71-.56-4.72 0l-.23.22L20.3 228.65a11.93 11.93 0 0 1-8.3 3.33ZM344 92.33l24 22.97V39.98h-24v52.35Z"
      fill="currentColor"
    />
    <path
      d="M287.09 276.48c-13.15 0-25.86-5.87-35.78-16.52-9.67-10.38-15.57-24.24-16.62-39.01-1.12-15.73 3.68-30.21 13.51-40.76 9.75-10.46 23.56-16.22 38.89-16.22s29.02 5.8 38.81 16.32c9.89 10.63 14.71 25.07 13.58 40.67-1.06 14.77-6.97 28.62-16.63 39-9.92 10.66-22.62 16.52-35.77 16.52Zm0-92.06c-9.59 0-18.09 3.45-23.92 9.71-6 6.44-8.79 15.21-8.07 25.37.72 10.13 4.69 19.55 11.18 26.52 6.01 6.45 13.4 10 20.81 10s14.79-3.55 20.79-10c6.49-6.97 10.47-16.4 11.2-26.53.71-9.87-2.18-18.84-8.16-25.26-5.88-6.32-14.34-9.8-23.83-9.8ZM371.36 389H202.81a17.73 17.73 0 0 1-14.06-6.82 19.33 19.33 0 0 1-3.36-16.49c5.38-21.61 18.72-39.53 38.56-51.83 17.61-10.91 40.03-16.92 63.13-16.92s45.38 5.76 63.1 16.66c19.89 12.23 33.24 30.25 38.6 52.13a19.39 19.39 0 0 1-3.4 16.48 17.69 17.69 0 0 1-14.03 6.79Zm-165.55-20.46h162.57c-4.38-15.62-14.33-28.56-28.9-37.52-14.3-8.79-32.9-13.63-52.39-13.63s-37.94 4.92-52.35 13.85c-14.57 9.03-24.53 21.89-28.92 37.29ZM166.26 279.04c-22.49 0-42.27-20.91-44.08-46.62-.93-13.17 3.14-25.35 11.46-34.28 8.24-8.84 19.82-13.71 32.62-13.71s24.28 4.9 32.56 13.79c8.37 9 12.47 21.15 11.53 34.21-1.85 25.7-21.63 46.61-44.08 46.61Zm0-74.16c-7.06 0-13.33 2.56-17.65 7.2-4.48 4.8-6.56 11.34-6.02 18.89 1.06 14.97 11.9 27.61 23.68 27.61s22.6-12.65 23.68-27.62c.53-7.33-1.64-14.01-6.1-18.8-4.36-4.69-10.61-7.27-17.58-7.27Z"
      fill="currentColor"
    />
    <path
      d="M170.74 368.54H98.96a16.2 16.2 0 0 1-12.84-6.23 17.56 17.56 0 0 1-3.08-14.93c4.44-17.8 15.41-32.55 31.73-42.66 14.39-8.91 32.67-13.82 51.49-13.82 16.13 0 29.86 2.7 41.98 8.25a10.23 10.23 0 1 1-8.52 18.6c-9.39-4.3-20.33-6.39-33.46-6.39-15.06 0-29.52 3.82-40.72 10.75-10.35 6.41-17.68 15.33-21.39 25.97h66.58a10.23 10.23 0 1 1 0 20.46Z"
      fill="currentColor"
    />
  </svg>
);
