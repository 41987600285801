import React, { useEffect, useRef } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import classNames from 'classnames';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { useWebsiteContext } from '../../context';

const Modal = () => {
  const { modalContent, setModalContent = () => undefined } =
    useWebsiteContext();
  const modalRef = useRef();

  useEffect(() => {
    disablePageScroll(modalRef?.current);

    return () => {
      enablePageScroll(modalRef?.current);
    };
  }, []);

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center p-4 md:p-12"
      ref={modalRef as any}
    >
      <button
        type="button"
        className="fixed inset-0 z-0 bg-black bg-opacity-50"
        onClick={() => setModalContent(null)}
        aria-label="Close Modal"
      />

      <div className="relative flex flex-col w-full max-h-full gap-5 p-5 overflow-auto bg-white rounded shadow-lg md:overflow-visible md:max-w-lg md:p-8">
        <header
          className={classNames('flex items-center justify-between gap-5', {
            'absolute top-5 right-5 w-full': !modalContent?.title,
          })}
        >
          {modalContent?.title && (
            <h3 className="flex-1 font-semibold leading-none md:text-2xl text-primary-700">
              {modalContent.title}
            </h3>
          )}

          <button
            onClick={() => setModalContent(null)}
            type="button"
            className="ml-auto"
          >
            <IoCloseCircleOutline className="w-6 h-6 text-gray-500 transition-colors hover:text-gray-300" />
          </button>
        </header>

        <div>{modalContent?.content}</div>
      </div>
    </div>
  );
};

export default Modal;
