import React, { useCallback } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Logo } from '../../assets/icons';
import { Button } from '../ui/Button';
import { useWebsiteContext } from '../../context';
import DownloadButtons from '../ui/DownloadButtons';

const CampaignHeader: React.FC = () => {
  const { setModalContent, googlePlayLink, appStoreLink } = useWebsiteContext();

  const handleDownload = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (navigator.userAgent.match('/Android/i')) {
        window.location.href = googlePlayLink;
      } else if (navigator.userAgent.match(/iPhone/i)) {
        window.location.href = appStoreLink;
      } else {
        setModalContent({
          title: 'Get Nomadago',
          content: (
            <div>
              <p className="hidden mb-6 text-lg font-medium text-gray-500 sm:block">
                Visit this page on your mobile device and download Nomadago to
                start planning today!
              </p>

              <DownloadButtons />
            </div>
          ),
        });
      }
    }
  }, [googlePlayLink, appStoreLink]);

  return (
    <header className="relative">
      <div className="container flex items-center justify-between h-16 gap-6 py-3">
        <Logo className="text-blue-500 w-36" />

        <Button className="text-sm" onClick={handleDownload}>
          Get The App
        </Button>
      </div>

      <div className="absolute w-full top-full" aria-hidden="true">
        <StaticImage
          src="../../assets/images/header-shadow.png"
          alt="Header Background"
          placeholder="none"
        />
      </div>
    </header>
  );
};

export default CampaignHeader;
