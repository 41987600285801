import React from 'react';

interface PathWithPointsProps {
  className?: string;
}

export const PathWithPoints: React.FC<PathWithPointsProps> = ({
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 957 406"
    fill="none"
  >
    <path
      stroke="#0016B7"
      strokeDasharray="16 16"
      strokeLinecap="round"
      strokeWidth="4"
      d="M-31.5 250c76 296.5 514.57 73.48 673 69 335.5-9.5-77-328 421-316.5"
      opacity=".25"
    />
    <circle cx="793.5" cy="113.5" r="6.5" fill="#0016B7" />
    <circle cx="79.5" cy="384.5" r="6.5" fill="#0016B7" />
  </svg>
);
