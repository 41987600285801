const awsConfig = {
  aws_appsync_graphqlEndpoint: process.env.GATSBY_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_region: 'us-east-1',
  aws_user_pools_id: process.env.GATSBY_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.GATSBY_AWS_COGNITO_IDENTITY_POOL_ID,
  federationTarget: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_region: 'us-east-1',
  aws_user_files_s3_bucket: process.env.GATSBY_AWS_USER_FILES_S3_BUCKET,
  aws_user_files_s3_bucket_region: 'us-east-1',
  API: {
    endpoints: [
      {
        name: 'SendReportEmail',
        endpoint: 'https://rcjr6onskl.execute-api.us-east-1.amazonaws.com/prod',
      },
    ],
  },
};

export default awsConfig;
