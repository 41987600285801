import React, { useCallback, useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import { ApolloError, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/gatsby';
import { TextInput } from '../ui/TextInput';
import { TextArea } from '../ui/TextArea';
import { LocationInput } from '../ui/LocationInput';
import { createColiving } from '../../gql/mutations/createColiving';
import { Button } from '../ui/Button';
import { useWebsiteContext } from '../../context';
import { WebsiteInput } from '../ui/WebsiteInput';

export const AddColivingModal = () => {
  const { setModalContent } = useWebsiteContext();
  const [locationName, setLocationName] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState<LocationData | null>(null);
  const [website, setWebsite] = useState('');
  const formDataValid = useMemo(
    () => locationName && location && website,
    [locationName, location, website],
  );

  const [submitColiving, { loading: submitColivingLoading }] = useMutation(
    createColiving,
    {
      onError: (error: ApolloError) => {
        console.error('Error creating location: ', error);
        Sentry.captureException(error, {
          tags: {
            component: 'CreateColiving',
            query: 'createColiving',
          },
        });
      },
      refetchQueries: ['getBusinessData'],
    },
  );

  const handleSubmit = useCallback(async () => {
    if (!formDataValid) return;

    try {
      const { data } = await submitColiving({
        variables: {
          name: locationName,
          description,
          location,
          website,
        },
      });

      if (data?.createColiving) {
        setModalContent(null);
        navigate(`/dashboard/${data.createColiving.id}`);
      }
    } catch (error) {
      console.error('Error creating location: ', error);
      Sentry.captureException(error, {
        tags: {
          component: 'CreateColiving',
          query: 'createColiving',
        },
      });
    }
  }, [formDataValid, locationName, description, location, website]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
      className="flex flex-col gap-2"
    >
      <TextInput
        label="Name"
        value={locationName}
        onChange={setLocationName}
        placeholder='e.g. "The Coliving House"'
        required
      />

      <TextArea
        label="Description"
        value={description}
        onChange={setDescription}
        placeholder="Tell us about your coliving space. What makes it special? What are the amenities? What are the house rules?"
      />

      <LocationInput onSelect={setLocation} required />

      <WebsiteInput
        label="Website"
        onValid={value => setWebsite(value || '')}
        required
      />

      <div className="flex items-center justify-end mt-2">
        <Button
          htmlType="submit"
          className="text-sm"
          disabled={!formDataValid}
          loading={submitColivingLoading}
        >
          Add Location
        </Button>
      </div>
    </form>
  );
};
