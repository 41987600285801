import React, { useEffect, useMemo, useState } from 'react';
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import cn from 'classnames';
import cuid from 'cuid';
import { TextInput } from './TextInput';

export interface WebsiteInputProps {
  className?: string;
  label?: string;
  required?: boolean;
  initialValue?: string;
  onValid?: (value?: string) => void;
}

export const WebsiteInput: React.FC<WebsiteInputProps> = ({
  className,
  label,
  required,
  initialValue,
  onValid = () => undefined,
}) => {
  const id = useMemo(() => `WebsiteInput-${cuid()}`, []);
  const [value, setValue] = useState<string | undefined>('');
  const [hasEnteredOnce, setHasEnteredOnce] = useState(false);

  const isValid = useMemo(() => {
    if (!value) {
      return false;
    }

    const regex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    );

    return regex.test(value);
  }, [value]);

  useEffect(() => {
    setValue(initialValue || '');
    setHasEnteredOnce(false);
  }, [initialValue]);

  useEffect(() => {
    if (value && isValid) {
      setHasEnteredOnce(isValid);
      onValid(value);
    }

    if (!value || !isValid) {
      onValid(undefined);
    }
  }, [value, isValid]);

  return (
    <label
      htmlFor={id}
      data-testid="WebsiteInput"
      className={cn({ 'mt-2': !!label }, className)}
    >
      <div className="relative">
        <TextInput
          label="Website"
          value={value}
          onChange={setValue}
          required={required}
        />
        {hasEnteredOnce && (
          <div className="absolute top-0 flex items-center w-5 h-full right-2">
            {isValid ? (
              <IoCheckmarkCircleOutline className="text-green-500" size={20} />
            ) : (
              <IoCloseCircleOutline className="text-red-500" size={20} />
            )}
          </div>
        )}
      </div>

      {hasEnteredOnce && !isValid && (
        <p className="mt-1 text-xs text-red-500">
          Please enter a valid website
        </p>
      )}
    </label>
  );
};
