import React, { useCallback, useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import cn from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { Logo } from '../../assets/icons';
import { Button } from '../ui/Button';
import { useWebsiteContext } from '../../context';
import DownloadButtons from '../ui/DownloadButtons';
import { HamburgerButton } from '../ui/HamburgerButton';

const Header: React.FC = () => {
  const { setModalContent, googlePlayLink, appStoreLink } = useWebsiteContext();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const mobileNavRef = useRef<any>();

  useEffect(() => {
    if (mobileMenuOpen) {
      disablePageScroll(mobileNavRef?.current);
    } else {
      enablePageScroll(mobileNavRef?.current);
    }

    return () => {
      enablePageScroll(mobileNavRef?.current);
    };
  }, [mobileMenuOpen]);

  const fireEvent = useCallback(
    (url: string) => {
      // @ts-expect-error gtag is not defined
      if (typeof window !== 'undefined' && window.gtag) {
        console.log(
          `firing event - 
          ${
            url === googlePlayLink
              ? 'android_download_click'
              : 'ios_download_click'
          }`,
        );

        // @ts-expect-error gtag is not defined
        window.gtag(
          'event',
          url === googlePlayLink
            ? 'android_download_click'
            : 'ios_download_click',
        );
      }
    },
    [googlePlayLink, appStoreLink],
  );

  const handleDownload = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (navigator.userAgent.match('/Android/i')) {
        fireEvent(googlePlayLink);
        window.location.href = googlePlayLink;
      } else if (navigator.userAgent.match(/iPhone/i)) {
        fireEvent(appStoreLink);
        window.location.href = appStoreLink;
        window.open(appStoreLink, '_blank');
      } else {
        setModalContent({
          title: 'Get Nomadago',
          content: (
            <div>
              <p className="hidden mb-6 text-lg font-medium text-gray-500 sm:block">
                Visit this page on your mobile device and download Nomadago to
                start planning today!
              </p>

              <DownloadButtons />
            </div>
          ),
        });
      }
    }
  }, [googlePlayLink, appStoreLink]);

  const handleLinkClick = useCallback((path: string) => {
    setMobileMenuOpen(false);
    navigate(path);
  }, []);

  return (
    <header className="relative">
      <div className="container flex items-center justify-center h-16 gap-6 py-3 md:justify-between">
        <div className="flex items-center w-full gap-3 md:w-auto">
          <button
            type="button"
            onClick={() => handleLinkClick('/')}
            className="focus-ring"
          >
            <Logo className="text-blue-500 w-36" />
            <h1 className="sr-only">Nomadago</h1>
          </button>

          <Button
            className="ml-auto text-sm md:hidden"
            onClick={handleDownload}
          >
            Get The App
          </Button>

          <HamburgerButton
            className="md:hidden"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            isOpen={mobileMenuOpen}
          />
        </div>

        <nav
          ref={mobileNavRef}
          className={cn(
            'fixed md:static inset-0 transform top-16 bg-white transition-transform duration-300 ease-in-out z-50 overflow-auto',
            {
              'translate-x-full md:translate-x-0': !mobileMenuOpen,
              'translate-x-0': mobileMenuOpen,
            },
          )}
        >
          <ul className="flex flex-col gap-8 px-4 py-6 text-lg md:text-base md:gap-5 md:p-0 md:items-center md:flex-row">
            <li>
              <button
                type="button"
                onClick={() => handleLinkClick('/coliving/')}
                className="font-medium text-black transition-colors hover:text-blue-500"
              >
                Coliving
              </button>
            </li>

            <li>
              <button
                type="button"
                onClick={() => handleLinkClick('/coworking/')}
                className="font-medium text-black transition-colors hover:text-blue-500"
              >
                Coworking
              </button>
            </li>

            <li>
              <button
                type="button"
                onClick={() => handleLinkClick('/blog/')}
                className="font-medium text-black transition-colors hover:text-blue-500"
              >
                Blog
              </button>
            </li>

            <li>
              <button
                type="button"
                onClick={() => handleLinkClick('/about/')}
                className="font-medium text-black transition-colors hover:text-blue-500"
              >
                About
              </button>
            </li>

            <li className="hidden md:block">
              <Button className="text-sm" onClick={handleDownload}>
                Get The App
              </Button>
            </li>
          </ul>
        </nav>
      </div>

      <div className="absolute w-full top-full" aria-hidden="true">
        <StaticImage
          src="../../assets/images/header-shadow.png"
          alt="Header Background"
          placeholder="none"
        />
      </div>
    </header>
  );
};

export default Header;
