import React from 'react';
import cn from 'classnames';
import { getImageSourceUrl } from '../../utils/getImageSourceUrl';

interface UserImageProps {
  imgKey?: string;
  className?: string;
  label: string;
}

export const UserImage: React.FC<UserImageProps> = ({
  imgKey,
  className = '',
  label,
}) => (
  <figure
    className={cn(
      'overflow-hidden bg-gray-100 border-2 border-white rounded-full',
      className,
    )}
    style={{
      backgroundImage: `url(${getImageSourceUrl(imgKey)})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
    aria-label={label}
  />
);
