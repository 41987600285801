import React from 'react';

interface AppStoreProps {
  className?: string;
}

export const AppStore: React.FC<AppStoreProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 216 62.44"
    className={className}
  >
    <rect width="216" height="62.44" rx="7.38" ry="7.38" />
    <path
      d="m71.64 17.41-.91 2.98h-1.98l3.37-10.52h2.45l3.42 10.52h-2.06l-.95-2.98h-3.34Zm3.01-1.45-.83-2.59c-.2-.64-.37-1.36-.53-1.97h-.03c-.16.61-.31 1.34-.5 1.97l-.81 2.59h2.7Z"
      fill="#fff"
    />
    <path
      d="m80.86 12.79 1.23 3.86c.22.67.37 1.28.52 1.9h.05c.14-.62.31-1.22.52-1.9l1.22-3.86h2.01l-2.9 7.6h-1.89l-2.83-7.6h2.08Z"
      fill="#fff"
    />
    <path
      d="M94.07 18.56c0 .69.03 1.36.12 1.83h-1.73l-.14-.84h-.05a2.78 2.78 0 0 1-2.25 1.01c-1.53 0-2.39-1.11-2.39-2.26 0-1.9 1.7-2.89 4.51-2.87v-.12c0-.5-.2-1.33-1.55-1.33-.75 0-1.53.23-2.04.56l-.37-1.25a5.52 5.52 0 0 1 2.75-.67c2.44 0 3.14 1.55 3.14 3.22v2.73Zm-1.87-1.89c-1.36-.02-2.65.27-2.65 1.42 0 .75.48 1.09 1.09 1.09.78 0 1.33-.5 1.51-1.05.05-.14.05-.28.05-.42v-1.05Z"
      fill="#fff"
    />
    <path
      d="M97.73 11.69c-.64 0-1.06-.47-1.06-1.03 0-.59.44-1.05 1.08-1.05s1.06.45 1.08 1.05c0 .56-.42 1.03-1.08 1.03h-.02Zm-.95 8.69v-7.6h1.92v7.6h-1.92Z"
      fill="#fff"
    />
    <path d="M101.46 9.29h1.92v11.1h-1.92V9.29Z" fill="#fff" />
    <path
      d="M112.06 18.56c0 .69.03 1.36.12 1.83h-1.73l-.14-.84h-.05a2.78 2.78 0 0 1-2.25 1.01c-1.53 0-2.39-1.11-2.39-2.26 0-1.9 1.7-2.89 4.51-2.87v-.12c0-.5-.2-1.33-1.54-1.33-.75 0-1.53.23-2.04.56l-.37-1.25a5.52 5.52 0 0 1 2.75-.67c2.43 0 3.14 1.55 3.14 3.22v2.73Zm-1.87-1.89c-1.36-.02-2.65.27-2.65 1.42 0 .75.48 1.09 1.09 1.09.78 0 1.33-.5 1.51-1.05.05-.14.05-.28.05-.42v-1.05Z"
      fill="#fff"
    />
    <path
      d="M114.77 9.29h1.92v4.54h.03a2.77 2.77 0 0 1 2.44-1.22c1.86 0 3.18 1.55 3.17 3.86 0 2.73-1.73 4.09-3.45 4.09-.98 0-1.86-.38-2.4-1.31h-.03l-.09 1.14h-1.64c.03-.52.06-1.36.06-2.12V9.29Zm1.92 7.96a1.75 1.75 0 0 0 1.75 1.79c1.2 0 1.94-.95 1.94-2.5 0-1.34-.64-2.43-1.92-2.43-.78 0-1.5.56-1.7 1.4-.03.14-.06.31-.06.5v1.23Z"
      fill="#fff"
    />
    <path d="M124.59 9.29h1.92v11.1h-1.92V9.29Z" fill="#fff" />
    <path
      d="M130.65 17.13c.05 1.37 1.12 1.97 2.34 1.97.89 0 1.51-.14 2.11-.34l.28 1.31c-.66.28-1.56.48-2.65.48-2.47 0-3.92-1.51-3.92-3.84 0-2.11 1.28-4.09 3.72-4.09s3.28 2.03 3.28 3.7c0 .36-.03.64-.06.81h-5.09Zm3.34-1.34c.02-.7-.3-1.86-1.58-1.86-1.19 0-1.69 1.08-1.76 1.86h3.34Z"
      fill="#fff"
    />
    <path
      d="M145.27 20.56c-2.14 0-3.81-1.47-3.81-3.92s1.64-4.03 3.93-4.03 3.78 1.59 3.78 3.9c0 2.79-1.97 4.04-3.89 4.04h-.02Zm.06-1.39c1.09 0 1.86-1.06 1.86-2.61 0-1.19-.53-2.56-1.84-2.56s-1.91 1.33-1.91 2.59c0 1.47.73 2.58 1.87 2.58h.02Z"
      fill="#fff"
    />
    <path
      d="M151.43 15.05c0-.87-.03-1.61-.06-2.26h1.67l.09 1.14h.05c.34-.59 1.17-1.31 2.44-1.31s2.7.86 2.7 3.26v4.51h-1.92V16.1c0-1.09-.41-1.92-1.45-1.92-.76 0-1.29.55-1.5 1.12-.06.16-.09.39-.09.61v4.48h-1.92v-5.34Z"
      fill="#fff"
    />
    <path
      d="M167.16 10.84v1.95h1.83v1.44h-1.83v3.34c0 .94.25 1.4.98 1.4.34 0 .53-.02.75-.08l.03 1.45c-.28.11-.8.2-1.39.2-.72 0-1.29-.23-1.65-.62-.42-.44-.61-1.14-.61-2.14v-3.56h-1.08v-1.44h1.08v-1.42l1.89-.53Z"
      fill="#fff"
    />
    <path
      d="M171.13 9.29h1.92v4.53h.03c.23-.36.56-.66.95-.86.39-.22.84-.34 1.33-.34 1.29 0 2.65.86 2.65 3.29v4.48h-1.92v-4.28c0-1.09-.41-1.94-1.47-1.94-.75 0-1.28.5-1.5 1.08a1.8 1.8 0 0 0-.08.58v4.56h-1.92V9.29Z"
      fill="#fff"
    />
    <path
      d="M182.1 17.13c.05 1.37 1.12 1.97 2.34 1.97.89 0 1.51-.14 2.11-.34l.28 1.31c-.66.28-1.56.48-2.65.48-2.47 0-3.92-1.51-3.92-3.84 0-2.11 1.28-4.09 3.72-4.09s3.28 2.03 3.28 3.7c0 .36-.03.64-.06.81h-5.09Zm3.34-1.34c.02-.7-.3-1.86-1.58-1.86-1.19 0-1.69 1.08-1.76 1.86h3.34Z"
      fill="#fff"
    />
    <g>
      <path
        d="M47.76 30.59c-.05-5.21 4.26-7.74 4.46-7.86a9.61 9.61 0 0 0-7.56-4.09c-3.18-.33-6.26 1.9-7.88 1.9s-4.15-1.87-6.83-1.82a10.1 10.1 0 0 0-8.47 5.16c-3.66 6.34-.93 15.65 2.58 20.78 1.76 2.51 3.81 5.31 6.49 5.21 2.62-.11 3.6-1.67 6.77-1.67s4.06 1.67 6.8 1.61c2.82-.05 4.59-2.52 6.28-5.05a21.16 21.16 0 0 0 2.88-5.85 9.05 9.05 0 0 1-5.53-8.33"
        fill="#fff"
      />
      <path
        d="M42.59 15.28a9.23 9.23 0 0 0 2.11-6.61 9.37 9.37 0 0 0-6.07 3.14c-1.3 1.52-2.47 4.02-2.17 6.36 2.3.17 4.65-1.16 6.13-2.9"
        fill="#fff"
      />
      <g>
        <path
          d="M85.7 49.48h-3.66l-2-6.3h-6.96l-1.91 6.3h-3.56l6.9-21.44h4.26l6.93 21.44Zm-6.26-8.94-1.81-5.6a74.6 74.6 0 0 1-1.08-4.04h-.06a144 144 0 0 1-1.02 4.04l-1.78 5.6h5.76Z"
          fill="#fff"
        />
        <path
          d="M103.45 41.56c0 2.63-.71 4.71-2.13 6.23a6.21 6.21 0 0 1-4.74 2.03c-2.04 0-3.5-.73-4.39-2.19h-.06v8.14h-3.44V39.1c0-1.65-.04-3.35-.13-5.09h3.02l.19 2.45h.07c1.14-1.85 2.88-2.77 5.21-2.77 1.82 0 3.35.72 4.56 2.16 1.22 1.44 1.83 3.34 1.83 5.69m-3.5.13c0-1.5-.34-2.75-1.02-3.72a3.51 3.51 0 0 0-2.99-1.53c-.85 0-1.62.28-2.31.84a3.92 3.92 0 0 0-1.35 2.21c-.11.43-.16.77-.16 1.05v2.58a4.1 4.1 0 0 0 1.03 2.84 3.42 3.42 0 0 0 2.69 1.16 3.5 3.5 0 0 0 3.02-1.49 6.6 6.6 0 0 0 1.08-3.95"
          fill="#fff"
        />
        <path
          d="M121.23 41.56c0 2.63-.71 4.71-2.13 6.23a6.21 6.21 0 0 1-4.74 2.03c-2.03 0-3.5-.73-4.39-2.19h-.06v8.14h-3.43V39.1c0-1.65-.04-3.35-.13-5.09h3.02l.19 2.45h.07c1.14-1.85 2.88-2.77 5.21-2.77 1.82 0 3.34.72 4.56 2.16 1.22 1.44 1.83 3.34 1.83 5.69m-3.5.13c0-1.5-.34-2.75-1.02-3.72a3.51 3.51 0 0 0-2.99-1.53c-.85 0-1.62.28-2.31.84a3.92 3.92 0 0 0-1.35 2.21c-.11.43-.16.77-.16 1.05v2.58a4.1 4.1 0 0 0 1.03 2.84 3.4 3.4 0 0 0 2.69 1.16 3.5 3.5 0 0 0 3.02-1.49 6.6 6.6 0 0 0 1.08-3.95"
          fill="#fff"
        />
        <path
          d="M141.11 43.46c0 1.82-.63 3.31-1.91 4.45-1.4 1.25-3.34 1.88-5.84 1.88-2.31 0-4.16-.44-5.56-1.33l.8-2.86a9.44 9.44 0 0 0 4.97 1.37 4.7 4.7 0 0 0 3.02-.87 2.88 2.88 0 0 0 1.08-2.34c0-.87-.3-1.6-.89-2.2a8.18 8.18 0 0 0-2.96-1.66c-3.75-1.4-5.63-3.45-5.63-6.15 0-1.76.66-3.21 1.97-4.33 1.31-1.13 3.06-1.69 5.25-1.69 1.95 0 3.57.34 4.86 1.02l-.86 2.8a8.43 8.43 0 0 0-4.1-.99c-1.21 0-2.15.3-2.83.89a2.56 2.56 0 0 0-.86 1.94c0 .85.33 1.55.98 2.1.57.51 1.61 1.06 3.12 1.66 1.84.74 3.2 1.61 4.07 2.61.87 1 1.3 2.24 1.3 3.72"
          fill="#fff"
        />
        <path
          d="M152.46 36.59h-3.78v7.51c0 1.91.67 2.86 2.01 2.86a5.9 5.9 0 0 0 1.52-.16l.09 2.61c-.67.25-1.57.38-2.67.38a4.1 4.1 0 0 1-3.18-1.24c-.76-.83-1.15-2.22-1.15-4.17v-7.79h-2.26v-2.58h2.26v-2.83l3.37-1.02v3.85h3.78v2.58Z"
          fill="#fff"
        />
        <path
          d="M169.54 41.62c0 2.38-.68 4.33-2.03 5.85-1.42 1.57-3.31 2.35-5.66 2.35s-4.08-.75-5.42-2.26a8.19 8.19 0 0 1-2.02-5.69c0-2.4.69-4.36 2.08-5.88 1.39-1.53 3.26-2.29 5.61-2.29s4.09.75 5.47 2.26a8.13 8.13 0 0 1 1.97 5.66m-3.56.11c0-1.42-.31-2.65-.92-3.67-.72-1.23-1.75-1.85-3.08-1.85s-2.43.62-3.15 1.85c-.62 1.02-.92 2.26-.92 3.73s.3 2.65.92 3.67c.74 1.23 1.78 1.85 3.12 1.85s2.34-.63 3.08-1.88a6.9 6.9 0 0 0 .95-3.7"
          fill="#fff"
        />
        <path
          d="M180.71 37.04c-.34-.06-.7-.09-1.08-.09-1.21 0-2.14.46-2.8 1.37a5.16 5.16 0 0 0-.86 3.05v8.11h-3.43l.03-10.59c0-1.78-.04-3.4-.13-4.87h2.99l.13 2.96h.09a5.26 5.26 0 0 1 1.72-2.45 4.16 4.16 0 0 1 2.48-.83c.32 0 .6.02.86.06v3.28Z"
          fill="#fff"
        />
        <path
          d="M196.07 41.01a8.6 8.6 0 0 1-.12 1.56h-10.3a4.5 4.5 0 0 0 1.49 3.5 5.1 5.1 0 0 0 3.37 1.08c1.52 0 2.91-.24 4.17-.73l.54 2.39c-1.46.64-3.19.96-5.19.96-2.39 0-4.28-.71-5.64-2.12-1.36-1.41-2.05-3.3-2.05-5.68s.64-4.27 1.91-5.82c1.33-1.65 3.14-2.48 5.4-2.48s3.92.83 5.06 2.48a8.37 8.37 0 0 1 1.36 4.87m-3.27-.89c.02-1.02-.2-1.9-.67-2.64a3 3 0 0 0-2.74-1.43c-1.12 0-2.03.47-2.73 1.4a5.08 5.08 0 0 0-1.01 2.67h7.15Z"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);
